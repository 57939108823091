<template>
    <div class="main">
        <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file"  name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
            accept=".xlsx,.xls,.xlsm,.xlsb,.doc,.docx,.ppt,.pptx,.pdf" />
            <label for="fileInput" class="file-label">
                <div v-if="isDragging">Release to drop files here.</div>
                <div v-else class="file-label" style="font-size: 15px !important; color: #667085;">
                    {{ fileName.name? fileName.name: 'Drag and drop file here' }}
                </div>
            </label>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
     
      isDragging: false, 
      fileName:''
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingpercent: {
      type: Number,
      default: 0,
    },
    files:{
      type: Array,
      default: () => []
    },
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    drop(event) {
      event.preventDefault();
      this.isDragging = false;
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    onChange(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      this.files = Array.from(files);
      this.fileName = this.files[0]
      console.log(this.fileName,files,"teefsdfw");
      this.$emit("update:files", this.files); 
    },
  },
};
</script>



<style lang="scss">
.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.dropzone-container {
    padding: 4rem;
    background: white;
    border: 2px dashed #98A2B3;
    width: 85%;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 15px !important;
    display: block;
    cursor: pointer;
}

</style>